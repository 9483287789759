export const NODE_ENV = {
  PRODUCTION: 'production',
};

export const DOKKU_INSTANCE_NAME = import.meta.env.VITE_DIGIFORMA_DOKKU_INSTANCE;

export const CONFIG = {
  env: import.meta.env.VITE_APP_ENV,
  serverRoot: import.meta.env.VITE_SERVER_ROOT,
  filestackApiKey: import.meta.env.VITE_FILESTACK_API_KEY,
  s3Bucket: import.meta.env.VITE_S3_BUCKET,
  pusherKey: import.meta.env.VITE_PUSHER_KEY,
  miroApiKey: import.meta.env.VITE_MIRO_API_KEY,
  getStreamApiKey: import.meta.env.VITE_GET_STREAM_API_KEY,
};

export const SENTRY_DOMAIN = 'a-world-for-us';
