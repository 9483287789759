import { useEffect } from 'react';

import Routes from 'src/components/routes/Routes';
import { RootErrorBoundary } from 'src/components/v2/RootErrorBoundary';
import { Providers } from 'src/Providers';
import { getGuestInfo, getUserCode } from 'src/user';

export function App({ sentryClient }) {
  useEffect(() => {
    if (getUserCode() === 'www' && window.location.pathname !== '/home') {
      window.location.pathname = '/home';
    }
  }, []);

  const guestInfo = getGuestInfo();

  if (guestInfo) {
    delete guestInfo.__typename;
    sentryClient.setUser({ ...guestInfo, userCode: getUserCode() });
  }

  return (
    <Providers>
      <RootErrorBoundary>
        <Routes />
      </RootErrorBoundary>
    </Providers>
  );
}
