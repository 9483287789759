import { lazy, Suspense } from 'react';
import { Routes as ReactRouterRoutes, Route } from 'react-router-dom';

import './Routes.scss';

const AboutPage = lazy(() => import('src/components/v2/AboutPage'));
const AccessRequestPage = lazy(() => import('src/components/v2/session/AccessRequestPage'));
const AnswerEvaluationPageV1 = lazy(() => import('src/components/evaluation/AnswerEvaluationPage'));
const AnswerEvaluationPage = lazy(() => import('src/components/v2/evaluations/AnswerEvaluationPage'));
const AttendanceOnSiteSignaturePage = lazy(() => import('src/components/v2/AttendanceOnSiteSignaturePage'));
const AttendanceSingleSignaturePage = lazy(() => import('src/components/v2/AttendanceSingleSignaturePage'));
const AttendancesPage = lazy(() => import('src/components/v2/AttendancesPage'));
const CalendarPage = lazy(() => import('src/components/v2/CalendarPage'));
const ColorConfigurationPage = lazy(() => import('src/components/v2/ColorConfiguration/ColorConfigurationPage'));
const EvaluationsPage = lazy(() => import('src/components/v2/EvaluationsPage'));
const EvaluationResultsPage = lazy(() => import('src/components/evaluation/EvaluationResultsPage'));
const LogInPage = lazy(() => import('src/components/v2/session/LogInPage'));
const LogOutPage = lazy(() => import('src/components/v2/session/LogOutPage'));
const ModulePage = lazy(() => import('src/components/v2/ModulePage'));
const ModulesPage = lazy(() => import('src/components/v2/ModulesPage'));
const NotFoundPage = lazy(() => import('src/components/v2/NotFoundPage'));
const PasswordlessValidationPage = lazy(() => import('src/components/v2/session/PasswordlessValidationPage'));
const ProgramPage = lazy(() => import('src/components/v2/ProgramPage'));
const RecordingsPage = lazy(() => import('src/components/v2/RecordingsPage'));
const RequireAdmin = lazy(() => import('src/components/routes/RequireAdmin'));
const RequireAdminOrIntructor = lazy(() => import('src/components/routes/RequireAdminOrInstructor'));
const RequireLogged = lazy(() => import('src/components/routes/RequireLogged'));
const RequireNotLogged = lazy(() => import('src/components/routes/RequireNotLogged'));
const RootPageDispatcher = lazy(() => import('src/components/v2/RootPage'));
const TermsOfServicePage = lazy(() => import('src/components/v2/TermsOfServicePage'));
const TrainingSessionAttendanceTrackingPage = lazy(() => import('src/components/v2/TrainingSessionAttendanceTrackingPage'));
const TrainingSessionDocumentsPage = lazy(() => import('src/components/v2/TrainingSessionDocumentsPage'));
const TrainingSessionFollowupPage = lazy(() => import('src/components/v2/TrainingSessionFollowupPage'));
const TrainingSessionTraineesPage = lazy(() => import('src/components/v2/TrainingSessionTraineesPage'));
const TrainingSessionPage = lazy(() => import('src/components/v2/TrainingSessionPage'));
const TutorialsPage = lazy(() => import('src/components/v2/TutorialsPage'));
const VideoConferenceFeedbackPage = lazy(() => import('src/components/v2/VideoConferenceFeedbackPage'));
const VideoConferencePageResolver = lazy(() => import('src/components/video_conference/VideoConferencePageResolver'));

export default function Routes() {
  return (
    <Suspense>
      <ReactRouterRoutes>
        <Route path='/videoconference-feedback' element={<VideoConferenceFeedbackPage />} />
        <Route path='/signin/passwordless_validation' element={<PasswordlessValidationPage />} />
        <Route path='*' element={<NotFoundPage />} />

        <Route element={<RequireNotLogged />}>
          <Route path='login' element={<LogInPage />} />
          <Route path='login/request_access' element={<AccessRequestPage />} />
        </Route>

        <Route element={<RequireLogged />}>
          <Route path='/' element={<RootPageDispatcher />} />
          <Route path='/tos' element={<TermsOfServicePage />} />
          <Route path='/tutorials' element={<TutorialsPage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/logout' element={<LogOutPage />} />
          <Route path='ts/:training_session_id/recordings' element={<RecordingsPage />} />
          <Route path='ts/:training_session_id/module/:id/videoconference' element={<VideoConferencePageResolver />} />
          <Route path='ts/:training_session_id/module/:module_id' element={<ModulePage />} />
          <Route path='ts/:training_session_id/modules' element={<ModulesPage />} />
          <Route path='ts/:training_session_id/program' element={<ProgramPage />} />
          <Route path='ts/:training_session_id/trainees' element={<TrainingSessionTraineesPage />} />
          <Route path='ts/:training_session_id/tracking' element={<TrainingSessionFollowupPage />} />
          <Route path='ts/:training_session_id/attendances' element={<AttendancesPage />} />
          <Route path='ts/:training_session_id/attendance/sign/single' element={<AttendanceSingleSignaturePage />} />
          <Route path='ts/:training_session_id/attendance/sign/class' element={<AttendanceOnSiteSignaturePage />} />
          <Route path='ts/:training_session_id/evaluations' element={<EvaluationsPage />} />
          <Route path='ts/:training_session_id/documents' element={<TrainingSessionDocumentsPage />} />
          <Route path='ts/:training_session_id/dates' element={<CalendarPage />} />
          <Route path='ts/:training_session_id' element={<TrainingSessionPage />} />
          <Route path='answer_evaluation/:evaluationId/:traineeId' element={<AnswerEvaluationPage />} />
          <Route path='answer_evaluation/live_v1/:evaluationId/:traineeId' element={<AnswerEvaluationPageV1 />} />
          <Route path='evaluation_results/:id' element={<EvaluationResultsPage />} />
        </Route>
        <Route element={<RequireAdminOrIntructor />}>
          <Route path='ts/:training_session_id/attendance' element={<TrainingSessionAttendanceTrackingPage />} />
        </Route>
        <Route element={<RequireAdmin />}>
          <Route path='/admin_page' element={<ColorConfigurationPage />} />
        </Route>
      </ReactRouterRoutes>
    </Suspense>
  );
}
